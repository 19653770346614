import React, { useState, useRef, useEffect } from 'react';
import '../Styles/VideoWithProgress.css';

const VideoWithProgress = ({ src, locationKey }) => {
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      const currentTime = video.currentTime;
      const duration = video.duration;
      setProgress((currentTime / duration) * 100);
    };

    const handleFullscreenChange = () => {
      if (document.fullscreenElement === video) {
        document.exitFullscreen();
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [locationKey]);

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
      setIsPaused(true);
    } else {
      video.play();
      setIsPaused(false);
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={`video-container ${isPaused ? 'paused' : ''}`}>
      <video
        ref={videoRef}
        autoPlay
        muted
        loop
        onClick={handleVideoClick}
        controlsList="nodownload nofullscreen"
        playsInline // Este atributo es crucial para iOS
      >
        <source src={src} type="video/mp4" />
      </video>
      {/* <div className='progress-bar'>
        <div className='progress' style={{ width: `${progress}%` }}></div>
      </div> */}
      <div>
        <span className='state-paused' style={{ opacity: isPaused ? 1 : 0 }}>PAUSED</span>
      </div>
    </div>
  );
};

export default VideoWithProgress;
